import logo from "./assets/img/logo.png";
import playBtn from "./assets/img/play-btn.png";
import intro from "./assets/img/intro.png";
import aboutBox from "./assets/img/about-box.png";
import aboutTitle from "./assets/img/about-title.png";

import ftTitlte from "./assets/img/ft-title.png";
import tokenomicsTitle from "./assets/img/tokenomics-title.png";
import tokenomicsChart from "./assets/img/Tokenomics_chart.png";
import roadmapTitlte from "./assets/img/roadmap-title.png";
import roadmapBox from "./assets/img/roadmap-box.png";
import nftBox from "./assets/img/nft-item-box.png";
import nft0 from "./assets/img/NFT-00.png";
import nft1 from "./assets/img/NFT-01.png";
import nft2 from "./assets/img/NFT-02.png";
import nft3 from "./assets/img/NFT-03.png";
import nft4 from "./assets/img/NFT-04.png";
import nft5 from "./assets/img/NFT-05.png";
import nft6 from "./assets/img/NFT-06.png";
import next from "./assets/img/next.png";
import prev from "./assets/img/prev.png";
import seed1 from "./assets/img/seed-1.png";
import seed2 from "./assets/img/seed-2.png";
import seed3 from "./assets/img/seed-3.png";
import seed4 from "./assets/img/seed-4.png";
import seed5 from "./assets/img/seed-5.png";
import seed6 from "./assets/img/seed-6.png";
import seedcominsoon from "./assets/img/seed-comingsoon.png";
import board from "./assets/img/board.png";
import subscribe from "./assets/img/subscribe.png";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";

import "animate.css";

import "./App.css";
import { useEffect } from "react";

const NextArrow = (props) => {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-12 lg:w-16 absolute -bottom-3 right-24 md:right-44 z-50"
    >
      <img src={next} alt="" />
    </div>
  );
};
const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-12 lg:w-16 absolute -bottom-3 left-24 md:left-44 z-50"
    >
      <img src={prev} alt="" />
    </div>
  );
};

function App() {
  // const images = [nft0, nft1, nft2, nft3, nft4, nft5, nft6];

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="App">
      <header className="header h-[1076px]  relative">
        <video
          className="w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source
            src="https://s3-figma-videos-production-sig.figma.com/video/1184793714877960604/TEAM/11a3/0260/-5fcd-4870-8a6c-5bebdd0c1319?Expires=1705881600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jv06ySqYAni-vcimXWhficJh8i0UakdTZprpJX2eEYTv~aAU9fUgeN4kSqDAlMyqYGZl5brvmyx3~6cJb3QSd~OrESELhX5040vxxSDvsgVRoJGEdJntxZ7pUgndH39MbaVTJAXS1XiafIt38n9Lfgoee~PEn6fcHGT2IvOm9MR-eI0fGf7Oh~OolMguaqGdOff3E6SxfuWmsrh3YSbtqEqfQsMT~lUdbxjcWzwIgqqRCV5yheZI9v-vRyjk~kK999zZ-D4fik26yed7Y71sp66~aC27wZXi0Em3ESbG4I0KX7RaK5kvoOLAu63hlA2rntEeXAz6MhN7~9ULkc2SWw__"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 w-full left-1/2 -translate-x-1/2 max-w-[1335px] h-[150px] lg:h-[116px] mx-auto bg-[url('./assets/img/header.png')] bg-cover bg-center">
          <div className="flex flex-col lg:flex-row gap-3 justify-center lg:justify-between items-center h-full px-2 lg:px-5 xl:px-16 -mt-2">
            <div className="w-32">
              <img src={logo} alt="" />
            </div>
            <ul className="flex items-center gap-2 lg:gap-4 lg:text-xl">
              <li className="hidden lg:block">
                <a
                  href="/"
                  className="py-3 px-5 bg-transparent rounded-xl transition-all hover:text-[#A4FF00] hover:bg-black/10"
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="py-3 px-5 bg-transparent rounded-xl transition-all hover:text-[#A4FF00] hover:bg-black/10"
                >
                  NFT Market
                </a>
              </li>
              <li>
                <a
                  href="https://solfarm-docs.gitbook.io/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="py-3 px-5 bg-transparent rounded-xl transition-all hover:text-[#A4FF00] hover:bg-black/10"
                >
                  White Paper
                </a>
              </li>
              <li>
                <a
                  href="#roadmap"
                  className="py-3 px-5 bg-transparent rounded-xl transition-all hover:text-[#A4FF00] hover:bg-black/10"
                >
                  Roadmap
                </a>
              </li>
              <li className="hidden lg:block">
                <a
                  href="#tokenomics"
                  className="py-3 px-5 bg-transparent rounded-xl transition-all hover:text-[#A4FF00] hover:bg-black/10"
                >
                  Tokenomics
                </a>
              </li>
            </ul>
            <a
              href="https://app.solfarm.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="hidden lg:block w-36 transition-all hover:opacity-75"
            >
              <img src={playBtn} alt="" />
            </a>
          </div>
        </div>
        <div className="absolute max-w-[600px] w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img
            className="animate__animated animate__bounce animate__repeat-2"
            src={intro}
            alt=""
          />
          <a
            href="https://app.solfarm.net/"
            target="_blank"
            rel="noopener noreferrer"
            className="block lg:hidden w-36 mx-auto transition-all hover:opacity-75 mt-5"
          >
            <img src={playBtn} alt="" />
          </a>
        </div>
      </header>

      <main>
        <section
          data-aos="fade-up"
          className="-mt-28 relative bg-[url('./assets/img/bg-about.png')] bg-cover h-[1037px] flex items-center justify-center"
        >
          <div className="relative max-w-5xl mx-auto">
            <img className="hidden lg:block" src={aboutBox} alt="" />
            <div className="block lg:hidden max-w-md mx-auto">
              <img src={aboutTitle} alt="" />
            </div>
            <div className="relative lg:absolute max-w-[720px] w-full top-1/2 left-1/2 -translate-x-1/2 lg:-translate-y-1/2 px-2">
              <p className="text-xl text-center leading-relaxed pt-10 max-w-full w-break">
                Welcome to the digital farm game on the SOLFARM, where every
                blade of grass sprouting becomes an exciting money-making
                opportunity. With SOLFARM, you can mine coins, expand your farm,
                and create unique NFTs with each success. Join now to explore
                the vibrant world of Play-to-Earn, where creativity and earning
                money blend seamlessly. Make sure you're ready to elevate your
                farm to new heights on the SOL platform!
                <br />
                CA: BjBzvw6VX7UJtrC7BaYLG1dHBiwrXP1T9j2YfDEdP4zU
              </p>
              <div className="flex justify-center gap-5 items-center mt-6">
                <a
                  href="https://twitter.com/SolFarmOfficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-all hover:opacity-75"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61"
                    height="62"
                    viewBox="0 0 61 62"
                    fill="none"
                  >
                    <path
                      d="M59.5048 39.6597C59.095 39.7958 58.6828 39.9256 58.2745 40.0459L59.1236 41.2259C57.884 45.752 55.6233 49.9572 52.3715 53.2051C47.1624 58.6257 39.2958 61.4724 31.6261 61.518C23.9564 61.8004 16.0798 59.2219 10.8714 53.7927C5.54573 48.4886 2.89216 40.3315 3.00716 32.3521C3.00047 31.8691 3.00381 31.3861 3.01719 30.9032L15.3612 28.5433L7.60809 26.7507L3.505 25.9845L0.892334 25.3812L9.68586 23.4933L1.75911 20.3319C2.97398 15.5147 7.53244 14.2333 10.8668 10.9107C16.0751 5.48307 23.9525 2.90299 31.6215 3.18539C39.2912 3.23101 47.1578 6.07696 52.3669 11.4983C57.6926 16.8118 60.3562 24.6992 60.2396 32.3521C60.2767 34.8089 60.03 37.2616 59.5048 39.6597Z"
                      fill="#451000"
                    />
                    <path
                      d="M57.2348 36.4983C55.5669 37.0489 53.8565 37.5122 52.6247 37.6034C53.7052 37.712 55.2983 37.8866 56.8497 38.0605C55.6101 42.5874 53.3494 46.7918 50.0977 50.0405C44.8886 55.4611 37.022 58.307 29.3523 58.3526C21.6825 58.635 13.8059 56.0573 8.59757 50.6273C3.27189 45.3232 0.618316 37.1669 0.73332 29.1867C0.726631 28.7048 0.729976 28.2218 0.743354 27.7378L13.0873 25.3779H0.88846C0.971304 24.5205 1.08554 23.6679 1.23116 22.8199L7.41203 20.3326H1.75909C2.97396 15.5154 5.26246 11.0727 8.59757 7.74687C13.8059 2.31926 21.6833 -0.260821 29.3523 0.0207855C37.022 0.0664089 44.8886 2.91315 50.0977 8.33446C55.4234 13.6472 58.087 21.5346 57.9704 29.1875C58.007 31.6454 57.7601 34.0991 57.2348 36.4983Z"
                      fill="#DEA263"
                    />
                    <path
                      d="M17.9474 45.5207C17.1061 45.4688 12.1887 45.1754 9.17392 45.5302C8.04627 45.6631 6.52035 45.8251 4.99829 45.9817C4.90567 45.8275 4.81485 45.6717 4.72583 45.5144L6.98345 45.3571C11.2633 45.066 17.0351 45.4554 17.9474 45.5207Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M50.1771 49.9596C50.1508 49.9879 50.1246 50.0146 50.0999 50.0382C49.7397 50.4121 49.3684 50.7732 48.9861 51.1214C48.6596 51.1214 48.3409 51.1143 48.0321 51.1065C37.556 50.8492 26.9108 47.149 26.7449 47.0908C33.8975 48.2574 41.9022 51.1065 48.0321 50.1995C48.5979 50.1177 49.325 50.0367 50.1771 49.9596Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M14.4982 36.6934C10.3195 37.9465 6.61774 36.293 1.43795 36.6501C1.40347 36.4818 1.37028 36.3129 1.33838 36.1436C5.66066 35.9233 9.27441 36.444 10.1126 36.5982C11.7041 36.8869 14.4982 36.6934 14.4982 36.6934Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M53.9235 13.3051C51.4112 13.0329 48.8464 12.4674 46.6049 11.4133C38.8935 7.78626 32.6934 8.4242 32.6934 8.4242C37.0156 6.61499 41.9724 9.46095 47.4354 10.6708C48.8749 10.9854 50.7636 11.2717 52.7657 11.5148C53.1774 12.0932 53.5633 12.69 53.9235 13.3051Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M16.0357 49.9383C14.2667 50.522 11.6177 50.898 9.1046 51.1387C9.01198 51.0482 8.92091 50.9578 8.82983 50.8657C13.1984 50.4323 16.4371 49.1517 18.5488 48.4508C21.7689 47.3818 20.2306 45.3484 20.2306 45.3484C22.6457 46.8485 20.358 48.5138 16.0357 49.9383Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M48.1518 6.52692C45.6047 6.55288 42.2611 6.34285 37.9149 5.68761C23.5935 3.52836 26.0503 1.8875 21.2201 2.7512C21.2201 2.7512 20.2839 0.583294 38.4382 5.34228C38.4382 5.34228 42.901 6.05024 47.4895 5.99832H47.4934C47.7157 6.17295 47.9357 6.346 48.1518 6.52692Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M54.7725 43.4944C54.6181 43.8004 54.4638 44.1041 54.2947 44.403C47.7148 43.931 44.7178 45.0323 40.8547 46.4607C41.0338 46.3514 47.5643 42.3672 54.7725 43.4944Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M31.5773 36.5109C31.5773 36.5109 26.1297 38.778 9.78531 34.336C9.78531 34.336 5.59038 33.665 0.934658 33.4503C0.899153 33.123 0.868283 32.7966 0.842041 32.4686C2.22054 32.5386 3.76421 32.6448 5.32873 32.7997C10.2392 33.2866 22.9513 36.9585 24.0072 36.5109C25.063 36.0634 31.5773 36.5109 31.5773 36.5109Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M3.35966 15.5973C5.5455 15.5414 8.71003 15.3825 11.3235 14.9452C8.81577 15.5745 5.34637 16.369 2.91895 16.6757C3.05942 16.3113 3.20632 15.9518 3.35966 15.5973Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M14.4912 14.1232C13.6646 14.4724 12.5554 14.7407 11.3267 14.9452C13.0934 14.5055 14.3854 14.1523 14.4912 14.1232Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M57.282 22.0908C51.663 22.739 43.7046 22.7107 41.8244 21.8603C38.9871 20.5782 27.1772 21.8603 27.1772 21.8603C27.1772 21.8603 33.7664 19.6735 43.3086 21.4088C48.5787 22.3669 53.5671 21.6306 56.9763 20.7795C57.0882 21.2137 57.1886 21.6519 57.282 22.0908Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M57.9743 29.4991C57.9743 29.8892 57.9691 30.2802 57.9588 30.6719C53.8488 30.093 47.7498 29.208 45.0553 28.6952C42.1794 28.1477 51.0092 29.3465 57.9743 29.4991Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M31.0656 30.0732L36.6915 38.1202H34.3826L29.7918 31.5538V31.5534L29.1178 30.5895L23.7549 22.9183H26.0638L30.3916 29.1092L31.0656 30.0732Z"
                      fill="#E8BE91"
                    />
                    <path
                      d="M40.0284 18H20.431C18.9392 18 17.7297 19.2095 17.7297 20.7013V40.2987C17.7297 41.7905 18.9392 43 20.431 43H40.0284C41.5203 43 42.7297 41.7905 42.7297 40.2987V20.7013C42.7297 19.2095 41.5203 18 40.0284 18ZM33.6755 39.2005L29.0299 32.4393L23.2134 39.2005H21.7102L28.3624 31.4682L21.7102 21.7867H26.7839L31.1831 28.1891L36.6908 21.7867H38.1941L31.8508 29.1603H31.8504L38.7493 39.2005H33.6755Z"
                      fill="#E8BE91"
                    />
                    <path
                      d="M30.0656 28.5344L35.6915 36.5814H33.3826L28.7918 30.015V30.0146L28.1178 29.0507L22.7549 21.3795H25.0638L29.3916 27.5704L30.0656 28.5344Z"
                      fill="#451000"
                    />
                    <path
                      d="M39.0284 16.4612H19.431C17.9392 16.4612 16.7297 17.6706 16.7297 19.1625V38.7599C16.7297 40.2517 17.9392 41.4612 19.431 41.4612H39.0284C40.5203 41.4612 41.7297 40.2517 41.7297 38.7599V19.1625C41.7297 17.6706 40.5203 16.4612 39.0284 16.4612ZM32.6755 37.6617L28.0299 30.9005L22.2134 37.6617H20.7102L27.3624 29.9294L20.7102 20.2479H25.7839L30.1831 26.6503L35.6908 20.2479H37.1941L30.8508 27.6215H30.8504L37.7493 37.6617H32.6755Z"
                      fill="#451000"
                    />
                  </svg>
                </a>
                <a
                  href="https://t.me/SolFarm_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-all hover:opacity-75"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="62"
                    viewBox="0 0 60 62"
                    fill="none"
                  >
                    <path
                      d="M59.7567 31.9189C59.7567 32.1285 59.7483 32.3357 59.7406 32.5445C58.4436 34.2967 60.3036 40.1707 59.2432 41.4999C59.2432 41.4999 60.2432 45.4999 56.2432 47.9999C53.7432 50.9999 45.5234 56.2367 45.3029 56.4455L45.2975 56.4502C41.2121 59.384 36.4206 61.4584 30.7503 61.5389C22.5066 61.4224 16.1173 57.0874 11.0594 52.0276C6.10419 46.8607 1.85944 40.3355 1.74536 31.9174C1.85944 23.4992 6.10419 16.974 11.0594 11.8071C12.6063 10.2436 14.2703 8.80572 16.0361 7.50652C19.6186 7.47446 23.7431 7.58471 26.9121 7.71998C24.1068 7.29384 21.1438 6.54319 18.624 5.80662C22.1513 3.7314 26.1633 2.36616 30.7495 2.29657C38.9932 2.41308 45.3818 6.74806 50.4404 11.8079C55.3971 16.9756 59.6419 23.5007 59.7567 31.9189Z"
                      fill="#451000"
                    />
                    <path
                      d="M58.2553 29.6224C58.2553 29.8319 58.2476 30.0391 58.2392 30.2479C56.943 32.0002 59.3004 36.1707 58.2392 37.5C57.7432 43.5 57.7432 43 56.2432 45C50.7432 51 44.022 53.9401 43.8014 54.1489L43.7961 54.1536C39.7106 57.0874 34.9192 59.1618 29.2488 59.2424C21.0044 59.1259 14.6158 54.7909 9.55722 49.7311C4.60274 44.5641 0.358011 38.039 0.243164 29.6208C0.358011 21.2026 4.60274 14.6775 9.55722 9.51053C11.1041 7.94706 12.7681 6.50918 14.5339 5.20995C18.1156 5.17711 22.2401 5.28814 25.4061 5.42341C22.6007 4.99727 19.6377 4.25053 17.118 3.51005C20.6461 1.43483 24.658 0.0648996 29.2443 0C37.4872 0.116506 43.8765 4.45149 48.9343 9.51131C53.8957 14.6759 58.1404 21.201 58.2553 29.6224Z"
                      fill="#DEA263"
                    />
                    <path
                      d="M48.4306 9.00461C45.8841 8.98663 43.7969 8.88889 43.2556 8.8623C43.8122 8.8842 45.9882 8.96865 48.3556 8.9319L48.4306 9.00461Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M31.3757 43.1989C23.4781 42.0823 14.6043 39.1329 7.8796 40.2753C6.76482 40.4653 5.08653 40.6553 3.06064 40.8406C2.83094 40.3871 2.61273 39.926 2.40601 39.4574C4.40357 39.3393 6.25107 39.2713 7.85893 39.2752C19.3934 39.3119 31.1919 43.1387 31.3757 43.1989Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M19.457 8.49402C19.1906 8.50262 14.9604 8.6504 9.84204 9.23293C10.0273 9.04996 10.2144 8.86751 10.4033 8.68559L19.457 8.49402Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M47.5269 50.2503C47.3593 50.445 47.1908 50.6357 47.0247 50.8226C43.1406 50.943 40.065 50.5607 39.2672 50.4317C37.5108 50.1463 34.4382 50.4317 34.4382 50.4317C38.6562 49.0633 42.4844 50.4372 47.5269 50.2503Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M43.881 5.14822C43.3887 5.32884 42.9377 5.49695 42.525 5.64395C39.003 6.89972 40.7449 9.10553 40.7449 9.10553C38.461 7.75046 39.9027 6.18505 43.2715 4.71973C43.4782 4.86099 43.6814 5.00382 43.881 5.14822Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M31.3756 8.19769C29.3222 9.83973 25.1953 12.1683 18.4424 12.0033C15.1654 11.9251 11.3372 11.7875 7.63452 11.6405C7.78382 11.4638 7.93465 11.2902 8.08701 11.1166C13.9649 11.4779 19.8106 11.6718 22.3448 11.2527C26.3308 10.588 29.5526 9.34712 31.3756 8.19769Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M54.5549 35.1154L54.3252 35.4071C54.172 35.5968 54.0284 35.7855 53.8941 35.9732C51.9524 35.569 49.7037 35.0654 47.099 34.4406C47.099 34.4406 42.1713 33.769 37.1242 33.9316H37.1196C36.871 33.7471 36.6255 33.5581 36.3831 33.3647C39.1868 33.2756 42.8696 33.4273 47.6679 34.0473C50.5092 34.4148 52.7533 34.7761 54.5549 35.1154Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M47.586 46.9755C47.586 46.9755 27.1601 50.2401 22.6872 51.4825C21.2394 51.8844 17.549 52.4466 13.2177 53.0237C12.6164 52.5431 12.0317 52.0479 11.4636 51.5381C15.8079 51.004 20.0809 50.405 23.1527 49.8076C32.4453 47.9975 47.586 46.9755 47.586 46.9755Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M19.467 45.7862C19.3904 45.8347 14.9933 48.6793 9.23872 49.3916C8.93807 49.0716 8.64024 48.7466 8.34521 48.4166C13.2078 48.2555 16.0223 47.1491 19.467 45.7862Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M57.3778 23.3889C56.5624 23.3474 55.7218 23.2943 54.8749 23.2325C49.4603 22.8118 35.3878 19.0641 34.2324 19.5817C33.077 20.0993 25.906 19.7615 25.906 19.7615C25.906 19.7615 31.8497 17.1319 49.9358 21.6436C49.9358 21.6436 53.0787 22.0784 57.0563 22.3028C57.1706 22.6593 57.2778 23.0214 57.3778 23.3889Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M0.670434 33.8846C0.60561 33.5369 0.547181 33.1851 0.495117 32.829C5.17474 33.0269 10.8076 33.0949 12.7094 32.4193C16.1617 31.1933 21.8903 28.2517 23.0793 28.3432C24.1443 28.4245 15.7054 34.5805 0.670434 33.8846Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M29.2513 5.77371C27.9657 5.74109 26.6836 5.62276 25.4131 5.41949C29.7374 5.60325 32.284 5.82531 29.2513 5.77371Z"
                      fill="#DEA263"
                    />
                    <path
                      d="M58.2322 28.8248C55.4529 29.4144 50.5184 30.2401 47.5859 29.4402C47.5859 29.4402 54.2256 29.1211 58.2016 28.2798C58.2146 28.4612 58.2238 28.6426 58.2322 28.8248Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M25.4061 13.6047C25.1258 13.5883 15.6824 13.0308 3.82178 16.992C4.17908 16.3664 4.55832 15.7568 4.95951 15.1631C10.1835 13.9589 18.2963 12.6007 25.4061 13.6047Z"
                      fill="#CA874C"
                    />
                    <g filter="url(#filter0_d_60_1081)">
                      <path
                        d="M17.8094 29.0567C25.4123 25.5807 30.4821 23.2892 33.0188 22.182C40.2615 19.0208 41.7665 18.4717 42.7474 18.4536C42.9632 18.4496 43.4455 18.5057 43.758 18.7717C44.0219 18.9964 44.0945 19.2999 44.1292 19.5129C44.1639 19.7259 44.2206 20.2127 44.1728 20.5902C43.228 28.0565 42.9847 29.4637 41.218 40.2659C40.8817 42.3223 40.1326 43.0044 39.4357 43.0717C38.2364 43.1875 37.2656 42.4613 36.185 41.6529C35.9011 41.4405 35.6095 41.2224 35.3042 41.0124C34.1062 40.1883 33.1803 39.5266 32.2519 38.8633L32.2514 38.8629C31.4012 38.2553 30.549 37.6463 29.484 36.9099C27.2678 35.3774 28.1955 34.4389 29.4326 33.1875C31.0602 31.541 35.0453 26.5523 36.5706 24.647C36.3404 24.7018 32.6735 27.2451 25.5699 32.2769C24.529 33.0269 23.5862 33.3923 22.7416 33.3732C21.8104 33.3521 20.0191 32.8207 18.6875 32.3665C18.4919 32.2998 18.3012 32.2368 18.1171 32.1761C16.7636 31.7295 15.7697 31.4015 15.8692 30.5687C15.928 30.0759 16.5748 29.5719 17.8094 29.0567Z"
                        fill="#451000"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M36.5706 24.647C36.3404 24.7018 32.6735 27.2451 25.5699 32.2769C24.529 33.0269 23.5862 33.3923 22.7416 33.3732C21.8104 33.3521 20.0191 32.8207 18.6875 32.3665C18.4919 32.2998 18.3012 32.2368 18.1171 32.1761C16.7636 31.7295 15.7697 31.4015 15.8692 30.5687C15.928 30.0759 16.5748 29.5719 17.8094 29.0567"
                        fill="#451000"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_60_1081"
                        x="15.8623"
                        y="18.4534"
                        width="29.1078"
                        height="25.8769"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="0.778973" dy="1.24636" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_60_1081"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_60_1081"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </a>
                <a className="transition-all hover:opacity-75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61"
                    height="62"
                    viewBox="0 0 61 62"
                    fill="none"
                  >
                    <path
                      d="M60.1504 34.0499C59.578 39.9482 57.1298 45.4785 53.6092 50.0186C52.974 50.3418 52.2975 50.5732 51.5994 50.706C46.7239 51.6368 48.6014 51.5282 48.6014 51.5282L50.1284 53.8601C44.9266 58.7458 38.3948 61.7572 31.2774 61.5265C24.0354 61.2886 17.4016 57.8308 12.1578 52.2259C6.95214 46.6266 3.13646 38.8817 2.4037 30.2052C2.25892 28.5246 2.24538 26.835 2.36321 25.1522L6.40548 23.656L2.66767 22.5024C3.55073 16.9982 5.93825 12.1695 9.56314 8.68954C14.7696 3.60875 22.5676 1.35221 31.2774 2.72865C39.2319 3.98537 44.9048 5.37292 50.0591 10.2468C49.1566 11.9967 42.8779 14.3285 41.6748 14.3285H53.3032C58.3391 19.8747 60.8684 26.5984 60.1504 34.0499Z"
                      fill="#451000"
                    />
                    <path
                      d="M58.6233 31.7181C58.0517 37.6163 55.6035 43.1467 52.0821 47.6867C51.4469 48.0101 50.7704 48.2415 50.0723 48.3741C45.1968 49.305 42.1466 49.9258 40.1617 50.8566C40.3743 50.7726 42.9993 49.7736 45.4919 50.8614C46.2372 51.1873 47.3453 51.4164 48.6013 51.5282C43.4003 56.414 36.8677 59.4253 29.7503 59.1946C22.5083 58.9567 15.8745 55.499 10.6307 49.8941C5.42503 44.294 1.60935 36.5499 0.87659 27.8734C0.731813 26.1928 0.718291 24.5031 0.836114 22.8203L4.87838 21.3242L1.14058 20.1705C2.02442 14.6663 4.41116 9.83686 8.03605 6.35769C13.2425 1.2769 21.0444 -0.97964 29.7503 0.396804C37.7048 1.65352 44.9048 5.37294 50.0591 10.2468C46.8609 10.8811 41.3508 11.9967 40.1477 11.9967H51.7761C56.812 17.5397 59.3413 24.2665 58.6233 31.7181Z"
                      fill="#DEA263"
                    />
                    <path
                      d="M28.8931 12.968C28.4259 12.8633 20.7619 11.165 17.6393 12.1339C14.4177 13.1353 11.3699 12.8118 10.6737 12.9728C10.2384 13.0742 5.88543 13.2503 2.67871 13.3692C3.22225 12.7634 3.78629 12.1751 4.37084 11.6042C4.43937 11.5368 4.50867 11.4702 4.57875 11.4028C5.98977 11.2593 7.44986 11.2403 8.64207 11.5099C12.1688 12.3083 17.13 11.2229 20.0042 11.2998C22.7756 11.3743 28.4913 12.8618 28.8931 12.968Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M54.9089 16.1007C53.6163 16.199 51.8447 16.3274 49.6853 16.4749C45.358 16.7683 39.5481 16.3869 38.4719 16.31C39.464 16.371 44.394 16.6557 47.438 16.2973C49.3193 16.0769 52.2854 15.7732 54.5546 15.548C54.6691 15.7312 54.7882 15.9159 54.9089 16.1007Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M34.0114 45.1074C29.9512 45.3231 24.399 45.4246 20.595 44.7197C14.4712 43.5843 12.1008 44.0061 6.48323 44.4937C6.22107 44.0782 5.96592 43.6554 5.71777 43.2251C10.1806 43.2148 19.1559 43.5193 22.4094 44.2812C24.6957 44.8156 29.6919 44.8902 33.6547 44.8513C33.7739 44.9354 33.893 45.0218 34.0114 45.1074Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M14.0766 46.8439C14.0766 46.8439 11.6626 46.87 8.27907 47.0905C8.216 47.0112 8.15527 46.9263 8.09375 46.8439H14.0766Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M58.251 34.2942C58.2292 34.4107 58.2067 34.5273 58.1818 34.6438C54.9953 34.5875 30.9752 34.1285 24.7058 33.0533C22.4032 32.6569 12.9185 33.2262 12.3875 33.2579C12.9006 33.2079 21.6393 32.358 24.3437 32.4539C27.13 32.5506 30.8561 33.6147 43.7087 33.9699C49.6393 34.1324 54.5818 34.2585 58.251 34.2942Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M33.5722 48.2435C33.4289 48.6526 32.8962 49.1489 32.0459 49.6596C33.087 48.8667 33.5722 48.2435 33.5722 48.2435Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M30.8327 5.24459C24.7261 2.84692 20.2983 5.93361 15.7857 6.71063C13.1046 7.17288 9.43767 7.41551 6.90063 7.53603C7.1602 7.2416 7.42965 6.95537 7.70894 6.67733C9.7624 6.73283 12.8484 6.43233 17.3976 5.24539C28.277 2.40766 30.7813 5.18592 30.8327 5.24459Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M56.5784 19.2095L27.1689 17.929C27.1689 17.929 32.6658 16.9712 38.3823 17.282C42.7127 17.5199 51.8267 18.2882 56.2475 18.5134C56.3622 18.7444 56.4725 18.9764 56.5784 19.2095Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M17.4787 25.6786C16.267 26.5302 13.4987 27.7481 7.70199 27.5427C4.69151 27.4365 2.49711 27.7869 0.913995 28.2936C0.900757 28.1532 0.888299 28.0137 0.875839 27.8733C0.86338 27.733 0.854803 27.6141 0.845459 27.4848C2.93941 26.8505 5.64152 26.3082 8.23696 26.6127C12.5058 27.1066 15.8956 26.6111 17.4787 25.6786Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M18.3135 24.9C18.1578 25.1815 17.8767 25.4439 17.4788 25.6786C18.1025 25.241 18.3135 24.9 18.3135 24.9Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M58.6466 27.0409C52.6505 25.9769 42.595 30.5232 37.1955 29.6035C31.0367 28.5545 34.0417 23.9669 39.2326 22.2574C42.574 21.1561 52.0439 22.6356 58.1295 23.7861C58.1459 23.8575 58.1622 23.9296 58.1778 24.001C46.9644 22.301 42.5919 22.9972 38.3861 23.8115C33.5706 24.7447 31.5981 32.2065 44.4235 27.8552C51.892 25.318 56.2536 25.7382 58.596 26.5295C58.6123 26.6976 58.6302 26.8688 58.6466 27.0409Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M54.208 44.6047C54.0341 44.8912 53.855 45.1746 53.6707 45.4547C50.6797 45.917 42.9759 47.2522 36.0236 49.657C27.2491 52.6961 25.5204 50.587 25.49 50.5482C25.5507 50.5775 28.0924 51.7613 37.1932 47.8365C41.6124 45.9344 48.3677 45.0297 54.208 44.6047Z"
                      fill="#CA874C"
                    />
                    <path
                      d="M45.7115 53.9418C45.2536 54.2795 44.7903 54.6046 44.3168 54.9146L30.8335 54.2803C33.851 54.3913 41.4831 54.1138 45.7115 53.9418Z"
                      fill="#CA874C"
                    />
                    <g filter="url(#filter0_d_60_1100)">
                      <path
                        d="M40.4884 21.2327C38.652 20.3596 36.6828 19.7163 34.6238 19.3479C34.5863 19.3408 34.5488 19.3586 34.5295 19.3941C34.2763 19.8609 33.9957 20.4698 33.7993 20.9484C31.5847 20.6048 29.3815 20.6048 27.2124 20.9484C27.0159 20.4591 26.7251 19.8609 26.4707 19.3941C26.4514 19.3598 26.414 19.342 26.3765 19.3479C24.3186 19.7152 22.3494 20.3584 20.5119 21.2327C20.4959 21.2398 20.4823 21.2517 20.4733 21.2671C16.738 27.0493 15.7148 32.6895 16.2167 38.2597C16.219 38.287 16.2338 38.313 16.2542 38.3296C18.7186 40.2049 21.1058 41.3434 23.4487 42.098C23.4862 42.1098 23.5259 42.0956 23.5498 42.0636C24.104 41.2794 24.598 40.4525 25.0216 39.5829C25.0466 39.532 25.0227 39.4716 24.9717 39.4515C24.188 39.1435 23.4419 38.7679 22.7241 38.3415C22.6674 38.3071 22.6628 38.223 22.7151 38.1827C22.8661 38.0654 23.0172 37.9434 23.1614 37.8202C23.1875 37.7977 23.2239 37.7929 23.2545 37.8071C27.9698 40.0379 33.0747 40.0379 37.7344 37.8071C37.7651 37.7918 37.8014 37.7965 37.8286 37.819C37.9729 37.9422 38.1239 38.0654 38.2761 38.1827C38.3284 38.223 38.3249 38.3071 38.2682 38.3415C37.5504 38.7762 36.8043 39.1435 36.0195 39.4503C35.9684 39.4704 35.9457 39.532 35.9707 39.5829C36.4034 40.4513 36.8974 41.2782 37.4414 42.0624C37.4641 42.0956 37.505 42.1098 37.5425 42.098C39.8967 41.3434 42.2839 40.2049 44.7483 38.3296C44.7699 38.313 44.7835 38.2881 44.7858 38.2609C45.3866 31.8211 43.7796 26.2272 40.5259 21.2682C40.5179 21.2517 40.5043 21.2398 40.4884 21.2327ZM25.7258 34.868C24.3061 34.868 23.1364 33.5176 23.1364 31.859C23.1364 30.2005 24.2835 28.85 25.7258 28.85C27.1794 28.85 28.3378 30.2124 28.3151 31.859C28.3151 33.5176 27.168 34.868 25.7258 34.868ZM35.2995 34.868C33.8799 34.868 32.7102 33.5176 32.7102 31.859C32.7102 30.2005 33.8572 28.85 35.2995 28.85C36.7532 28.85 37.9116 30.2124 37.8889 31.859C37.8889 33.5176 36.7532 34.868 35.2995 34.868Z"
                        fill="#451000"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_60_1100"
                        x="16.0977"
                        y="19.3464"
                        width="29.5912"
                        height="24.0021"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="0.778973" dy="1.24636" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_60_1100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_60_1100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          data-aos="fade-up"
          className="-mt-10 relative bg-[url('./assets/img/bg-ft.png')] bg-cover h-[1030px] bg-center py-32"
        >
          <div className="max-w-[500px] rounded-lg bg-[#bd542b] py-4 lg:py-0 px-2 lg:px-5 md:px-5 xl:px-0 lg:bg-transparent mx-auto text-center">
            <div className="max-w-sm mx-auto">
              <img src={ftTitlte} alt="" />
            </div>
            <p className="text-xl mt-16">
              Experience a journey where every action is not just about managing
              a farm, but also an exciting opportunity to earn money.
              Particularly, the combination of features such as The Farm, Money
              Tree, Seed Shop, Store House, and Referral System brings a unique
              and multi-dimensional gaming experience.
              <br />
              <br />
              And don't miss out on upcoming features like Visit Friend's Farm,
              Live Stock, Add Friend, Farm Expansion, and Piggy Bank, promising
              to enrich your farming adventure. Get ready to explore our world
              and start your Play-to-Earn journey today!
            </p>
            <a
              href="https://app.solfarm.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-36 inline-flex transition-all hover:opacity-75 mt-10"
            >
              <img src={playBtn} alt="" />
            </a>
          </div>
        </section>

        <section
          id="roadmap"
          data-aos="fade-up"
          className="-mt-10 relative bg-[url('./assets/img/bg-roadmap.png')] bg-cover py-32"
        >
          <div className="max-w-5xl mx-auto text-center">
            <div className="max-w-md mx-auto">
              <img src={roadmapTitlte} alt="" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
              <div
                data-aos="fade-right"
                data-aos-delay="200"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 1
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Game ideas and economic development</span>
                  </li>
                  <li className="mt-2">
                    <span>Develop game UI and website</span>
                  </li>
                  <li className="mt-2">
                    <span>Launch social accounts</span>
                  </li>
                  <li className="mt-2">
                    <span>Check game and server security</span>
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 2
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Build the game environment</span>
                  </li>
                  <li className="mt-2">
                    <span>In-game building</span>
                  </li>
                  <li className="mt-2">
                    <span>Release internal beta test</span>
                  </li>
                  <li className="mt-2">
                    <span>Initiate marketing plan</span>
                  </li>
                  <li className="mt-2">
                    <span>Start presale</span>
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="400"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 3
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Launch giveaway campaign</span>
                  </li>
                  <li className="mt-2">
                    <span>List token on Raydium</span>
                  </li>
                  <li className="mt-2">
                    <span>Attain Top 1 on Solana Trending</span>
                  </li>
                  <li className="mt-2">
                    <span>Update socials on Dexscreener and Dextools</span>
                  </li>
                  <li className="mt-2">
                    <span>Launch the game</span>
                  </li>
                  <li className="mt-2">
                    <span>List on CMC and CG</span>
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="500"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 4
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Collect player feedback</span>
                  </li>
                  <li className="mt-2">
                    <span>Offer NFT discounts</span>
                  </li>
                  <li className="mt-2">
                    <span>Conduct top server giveaway</span>
                  </li>
                  <li className="mt-2">
                    <span>Update Anti-inflation measures</span>
                  </li>
                  <li className="mt-2">
                    <span>Hold user competitions and giveaways</span>
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="600"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 5
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Grow the community</span>
                  </li>
                  <li className="mt-2">
                    <span>Host giveaways and contests</span>
                  </li>
                  <li className="mt-2">
                    <span>Collaborate with influencers and partners</span>
                  </li>
                  <li className="mt-2">
                    <span>Implement a YouTube marketing campaign</span>
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="700"
                className="relative max-w-xs mx-auto"
              >
                <img src={roadmapBox} alt="" />
                <h3 className="absolute text-2xl top-7 left-1/2 -translate-x-1/2">
                  Phase 6
                </h3>
                <ul className="absolute max-w-[252px] text-left left-14 top-28 list-disc text-[#C70000] text-lg">
                  <li className="">
                    <span>Continue game development</span>
                  </li>
                  <li className="mt-2">
                    <span>List on CEX</span>
                  </li>
                  <li className="mt-2">
                    <span>Implement multi-chain bridge</span>
                  </li>
                  <li className="mt-2">
                    <span>Update the roadmap</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-16">
              <div className="relative max-w-2xl mx-auto">
                <img src={nftBox} alt="" />

                <Slider className="-mt-[380px] md:-mt-[580px]" {...settings}>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img src={nft0} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img src={nft1} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img className="w-full" src={nft2} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img className="w-full" src={nft3} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img className="w-full" src={nft4} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img className="w-full" src={nft5} alt="" />
                  </div>
                  <div className="max-w-[220px] md:max-w-[300px]">
                    <img className="w-full" src={nft6} alt="" />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="relative mt-12 flex justify-center items-center lg:gap-2">
              <div className="w-24">
                <img src={seed1} alt="" />
              </div>
              <div className="w-24">
                <img src={seed2} alt="" />
              </div>
              <div className="w-24">
                <img src={seed3} alt="" />
              </div>
              <div className="w-24">
                <img src={seed4} alt="" />
              </div>
              <div className="w-24">
                <img src={seed5} alt="" />
              </div>
              <div className="w-24">
                <img src={seed6} alt="" />
              </div>
              <div className="w-24">
                <img src={seedcominsoon} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section
          id="tokenomics"
          data-aos="fade-up"
          data-aos-delay="200"
          className="pt-16 relative bg-[url('./assets/img/bg-tokenomics.png')] bg-cover"
        >
          <div className="max-w-[960px] mx-auto">
            <div className="max-w-xl mx-auto">
              <img src={tokenomicsTitle} alt="" />
            </div>
            <div className="mt-16 py-12 px-10 lg:px-16 rounded-3xl bg-white/70 border-[2px] border-[#00900E] flex flex-col lg:flex-row items-center justify-between gap-10">
              <div className="max-w-[430px]">
                <img src={tokenomicsChart} />
              </div>
              <div className="">
                <h3 className="text-2xl text-[#580C0C]">Tokenmetric</h3>
                <ul className="mt-5">
                  <li className="flex gap-10 text-xl">
                    <span className="text-[#580C0C]">Total supply</span>
                    <span className="text-[#04C000]">1,000,000 SFarm</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">FarmFi</span>
                    <span className="text-[#84B6FF]">40%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Marketing</span>
                    <span className="text-[#04C000]">3%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Team</span>
                    <span className="text-[#FF7BAC]">2%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Cex</span>
                    <span className="text-[#FF7277]">5%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Presale</span>
                    <span className="text-[#E9BE24]">45.4%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Liquidity</span>
                    <span className="text-[#FF9A62]">4.5%</span>
                  </li>
                </ul>
                <h3 className="text-2xl text-[#580C0C] mt-5">Buy/Sell tax</h3>
                <ul className="mt-5">
                  <li className="flex gap-10 text-lg">
                    <span className="text-[#580C0C]">Slip buy</span>
                    <span className="text-[#04C000]">0%</span>
                  </li>
                  <li className="flex gap-10 text-lg mt-2">
                    <span className="text-[#580C0C]">Slip buy</span>
                    <span className="text-[#04C000]">0%</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-20">
              <div className="relative max-w-5xl mx-auto" data-aos="fade-right">
                {/* <h2>Join Our Mailing List</h2>
                <h4>Be the First to Hear All the Latest SolFarm Game News</h4> */}
                <img src={board} alt="" />
                <div className="absolute w-full px-2 max-w-[700px] text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <h2 className="text-stroke-custom text-3xl lg:text-4xl">
                    Join Our Mailing List
                  </h2>
                  <h3 className="text-[#3C3838] text-md lg:text-xl mt-2 lg:mt-4">
                    Be the First to Hear All the Latest SolFarm Game News
                  </h3>
                  <div className="hidden lg:grid grid-cols-2 gap-2 lg:gap-4 mt-5">
                    <input
                      className="text-[#580C0C] px-3 py-2 text-lg border-black border-[1px] rounded-xl"
                      placeholder="Name"
                    />
                    <input
                      className="text-[#580C0C] px-3 py-2 text-lg border-black border-[1px] rounded-xl"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <button className="hidden lg:inline-block w-36 mt-4">
                    <img src={subscribe} alt="" />
                  </button>
                  <div className="flex justify-center gap-5 items-center mt-5 lg:mt-10">
                    <a
                      href="https://twitter.com/SolFarmOfficial"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition-all hover:opacity-75"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="61"
                        height="62"
                        viewBox="0 0 61 62"
                        fill="none"
                      >
                        <path
                          d="M59.5048 39.6597C59.095 39.7958 58.6828 39.9256 58.2745 40.0459L59.1236 41.2259C57.884 45.752 55.6233 49.9572 52.3715 53.2051C47.1624 58.6257 39.2958 61.4724 31.6261 61.518C23.9564 61.8004 16.0798 59.2219 10.8714 53.7927C5.54573 48.4886 2.89216 40.3315 3.00716 32.3521C3.00047 31.8691 3.00381 31.3861 3.01719 30.9032L15.3612 28.5433L7.60809 26.7507L3.505 25.9845L0.892334 25.3812L9.68586 23.4933L1.75911 20.3319C2.97398 15.5147 7.53244 14.2333 10.8668 10.9107C16.0751 5.48307 23.9525 2.90299 31.6215 3.18539C39.2912 3.23101 47.1578 6.07696 52.3669 11.4983C57.6926 16.8118 60.3562 24.6992 60.2396 32.3521C60.2767 34.8089 60.03 37.2616 59.5048 39.6597Z"
                          fill="#451000"
                        />
                        <path
                          d="M57.2348 36.4983C55.5669 37.0489 53.8565 37.5122 52.6247 37.6034C53.7052 37.712 55.2983 37.8866 56.8497 38.0605C55.6101 42.5874 53.3494 46.7918 50.0977 50.0405C44.8886 55.4611 37.022 58.307 29.3523 58.3526C21.6825 58.635 13.8059 56.0573 8.59757 50.6273C3.27189 45.3232 0.618316 37.1669 0.73332 29.1867C0.726631 28.7048 0.729976 28.2218 0.743354 27.7378L13.0873 25.3779H0.88846C0.971304 24.5205 1.08554 23.6679 1.23116 22.8199L7.41203 20.3326H1.75909C2.97396 15.5154 5.26246 11.0727 8.59757 7.74687C13.8059 2.31926 21.6833 -0.260821 29.3523 0.0207855C37.022 0.0664089 44.8886 2.91315 50.0977 8.33446C55.4234 13.6472 58.087 21.5346 57.9704 29.1875C58.007 31.6454 57.7601 34.0991 57.2348 36.4983Z"
                          fill="#DEA263"
                        />
                        <path
                          d="M17.9474 45.5207C17.1061 45.4688 12.1887 45.1754 9.17392 45.5302C8.04627 45.6631 6.52035 45.8251 4.99829 45.9817C4.90567 45.8275 4.81485 45.6717 4.72583 45.5144L6.98345 45.3571C11.2633 45.066 17.0351 45.4554 17.9474 45.5207Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M50.1771 49.9596C50.1508 49.9879 50.1246 50.0146 50.0999 50.0382C49.7397 50.4121 49.3684 50.7732 48.9861 51.1214C48.6596 51.1214 48.3409 51.1143 48.0321 51.1065C37.556 50.8492 26.9108 47.149 26.7449 47.0908C33.8975 48.2574 41.9022 51.1065 48.0321 50.1995C48.5979 50.1177 49.325 50.0367 50.1771 49.9596Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M14.4982 36.6934C10.3195 37.9465 6.61774 36.293 1.43795 36.6501C1.40347 36.4818 1.37028 36.3129 1.33838 36.1436C5.66066 35.9233 9.27441 36.444 10.1126 36.5982C11.7041 36.8869 14.4982 36.6934 14.4982 36.6934Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M53.9235 13.3051C51.4112 13.0329 48.8464 12.4674 46.6049 11.4133C38.8935 7.78626 32.6934 8.4242 32.6934 8.4242C37.0156 6.61499 41.9724 9.46095 47.4354 10.6708C48.8749 10.9854 50.7636 11.2717 52.7657 11.5148C53.1774 12.0932 53.5633 12.69 53.9235 13.3051Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M16.0357 49.9383C14.2667 50.522 11.6177 50.898 9.1046 51.1387C9.01198 51.0482 8.92091 50.9578 8.82983 50.8657C13.1984 50.4323 16.4371 49.1517 18.5488 48.4508C21.7689 47.3818 20.2306 45.3484 20.2306 45.3484C22.6457 46.8485 20.358 48.5138 16.0357 49.9383Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M48.1518 6.52692C45.6047 6.55288 42.2611 6.34285 37.9149 5.68761C23.5935 3.52836 26.0503 1.8875 21.2201 2.7512C21.2201 2.7512 20.2839 0.583294 38.4382 5.34228C38.4382 5.34228 42.901 6.05024 47.4895 5.99832H47.4934C47.7157 6.17295 47.9357 6.346 48.1518 6.52692Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M54.7725 43.4944C54.6181 43.8004 54.4638 44.1041 54.2947 44.403C47.7148 43.931 44.7178 45.0323 40.8547 46.4607C41.0338 46.3514 47.5643 42.3672 54.7725 43.4944Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M31.5773 36.5109C31.5773 36.5109 26.1297 38.778 9.78531 34.336C9.78531 34.336 5.59038 33.665 0.934658 33.4503C0.899153 33.123 0.868283 32.7966 0.842041 32.4686C2.22054 32.5386 3.76421 32.6448 5.32873 32.7997C10.2392 33.2866 22.9513 36.9585 24.0072 36.5109C25.063 36.0634 31.5773 36.5109 31.5773 36.5109Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M3.35966 15.5973C5.5455 15.5414 8.71003 15.3825 11.3235 14.9452C8.81577 15.5745 5.34637 16.369 2.91895 16.6757C3.05942 16.3113 3.20632 15.9518 3.35966 15.5973Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M14.4912 14.1232C13.6646 14.4724 12.5554 14.7407 11.3267 14.9452C13.0934 14.5055 14.3854 14.1523 14.4912 14.1232Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M57.282 22.0908C51.663 22.739 43.7046 22.7107 41.8244 21.8603C38.9871 20.5782 27.1772 21.8603 27.1772 21.8603C27.1772 21.8603 33.7664 19.6735 43.3086 21.4088C48.5787 22.3669 53.5671 21.6306 56.9763 20.7795C57.0882 21.2137 57.1886 21.6519 57.282 22.0908Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M57.9743 29.4991C57.9743 29.8892 57.9691 30.2802 57.9588 30.6719C53.8488 30.093 47.7498 29.208 45.0553 28.6952C42.1794 28.1477 51.0092 29.3465 57.9743 29.4991Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M31.0656 30.0732L36.6915 38.1202H34.3826L29.7918 31.5538V31.5534L29.1178 30.5895L23.7549 22.9183H26.0638L30.3916 29.1092L31.0656 30.0732Z"
                          fill="#E8BE91"
                        />
                        <path
                          d="M40.0284 18H20.431C18.9392 18 17.7297 19.2095 17.7297 20.7013V40.2987C17.7297 41.7905 18.9392 43 20.431 43H40.0284C41.5203 43 42.7297 41.7905 42.7297 40.2987V20.7013C42.7297 19.2095 41.5203 18 40.0284 18ZM33.6755 39.2005L29.0299 32.4393L23.2134 39.2005H21.7102L28.3624 31.4682L21.7102 21.7867H26.7839L31.1831 28.1891L36.6908 21.7867H38.1941L31.8508 29.1603H31.8504L38.7493 39.2005H33.6755Z"
                          fill="#E8BE91"
                        />
                        <path
                          d="M30.0656 28.5344L35.6915 36.5814H33.3826L28.7918 30.015V30.0146L28.1178 29.0507L22.7549 21.3795H25.0638L29.3916 27.5704L30.0656 28.5344Z"
                          fill="#451000"
                        />
                        <path
                          d="M39.0284 16.4612H19.431C17.9392 16.4612 16.7297 17.6706 16.7297 19.1625V38.7599C16.7297 40.2517 17.9392 41.4612 19.431 41.4612H39.0284C40.5203 41.4612 41.7297 40.2517 41.7297 38.7599V19.1625C41.7297 17.6706 40.5203 16.4612 39.0284 16.4612ZM32.6755 37.6617L28.0299 30.9005L22.2134 37.6617H20.7102L27.3624 29.9294L20.7102 20.2479H25.7839L30.1831 26.6503L35.6908 20.2479H37.1941L30.8508 27.6215H30.8504L37.7493 37.6617H32.6755Z"
                          fill="#451000"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://t.me/SolFarm_Portal"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition-all hover:opacity-75"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="62"
                        viewBox="0 0 60 62"
                        fill="none"
                      >
                        <path
                          d="M59.7567 31.9189C59.7567 32.1285 59.7483 32.3357 59.7406 32.5445C58.4436 34.2967 60.3036 40.1707 59.2432 41.4999C59.2432 41.4999 60.2432 45.4999 56.2432 47.9999C53.7432 50.9999 45.5234 56.2367 45.3029 56.4455L45.2975 56.4502C41.2121 59.384 36.4206 61.4584 30.7503 61.5389C22.5066 61.4224 16.1173 57.0874 11.0594 52.0276C6.10419 46.8607 1.85944 40.3355 1.74536 31.9174C1.85944 23.4992 6.10419 16.974 11.0594 11.8071C12.6063 10.2436 14.2703 8.80572 16.0361 7.50652C19.6186 7.47446 23.7431 7.58471 26.9121 7.71998C24.1068 7.29384 21.1438 6.54319 18.624 5.80662C22.1513 3.7314 26.1633 2.36616 30.7495 2.29657C38.9932 2.41308 45.3818 6.74806 50.4404 11.8079C55.3971 16.9756 59.6419 23.5007 59.7567 31.9189Z"
                          fill="#451000"
                        />
                        <path
                          d="M58.2553 29.6224C58.2553 29.8319 58.2476 30.0391 58.2392 30.2479C56.943 32.0002 59.3004 36.1707 58.2392 37.5C57.7432 43.5 57.7432 43 56.2432 45C50.7432 51 44.022 53.9401 43.8014 54.1489L43.7961 54.1536C39.7106 57.0874 34.9192 59.1618 29.2488 59.2424C21.0044 59.1259 14.6158 54.7909 9.55722 49.7311C4.60274 44.5641 0.358011 38.039 0.243164 29.6208C0.358011 21.2026 4.60274 14.6775 9.55722 9.51053C11.1041 7.94706 12.7681 6.50918 14.5339 5.20995C18.1156 5.17711 22.2401 5.28814 25.4061 5.42341C22.6007 4.99727 19.6377 4.25053 17.118 3.51005C20.6461 1.43483 24.658 0.0648996 29.2443 0C37.4872 0.116506 43.8765 4.45149 48.9343 9.51131C53.8957 14.6759 58.1404 21.201 58.2553 29.6224Z"
                          fill="#DEA263"
                        />
                        <path
                          d="M48.4306 9.00461C45.8841 8.98663 43.7969 8.88889 43.2556 8.8623C43.8122 8.8842 45.9882 8.96865 48.3556 8.9319L48.4306 9.00461Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M31.3757 43.1989C23.4781 42.0823 14.6043 39.1329 7.8796 40.2753C6.76482 40.4653 5.08653 40.6553 3.06064 40.8406C2.83094 40.3871 2.61273 39.926 2.40601 39.4574C4.40357 39.3393 6.25107 39.2713 7.85893 39.2752C19.3934 39.3119 31.1919 43.1387 31.3757 43.1989Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M19.457 8.49402C19.1906 8.50262 14.9604 8.6504 9.84204 9.23293C10.0273 9.04996 10.2144 8.86751 10.4033 8.68559L19.457 8.49402Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M47.5269 50.2503C47.3593 50.445 47.1908 50.6357 47.0247 50.8226C43.1406 50.943 40.065 50.5607 39.2672 50.4317C37.5108 50.1463 34.4382 50.4317 34.4382 50.4317C38.6562 49.0633 42.4844 50.4372 47.5269 50.2503Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M43.881 5.14822C43.3887 5.32884 42.9377 5.49695 42.525 5.64395C39.003 6.89972 40.7449 9.10553 40.7449 9.10553C38.461 7.75046 39.9027 6.18505 43.2715 4.71973C43.4782 4.86099 43.6814 5.00382 43.881 5.14822Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M31.3756 8.19769C29.3222 9.83973 25.1953 12.1683 18.4424 12.0033C15.1654 11.9251 11.3372 11.7875 7.63452 11.6405C7.78382 11.4638 7.93465 11.2902 8.08701 11.1166C13.9649 11.4779 19.8106 11.6718 22.3448 11.2527C26.3308 10.588 29.5526 9.34712 31.3756 8.19769Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M54.5549 35.1154L54.3252 35.4071C54.172 35.5968 54.0284 35.7855 53.8941 35.9732C51.9524 35.569 49.7037 35.0654 47.099 34.4406C47.099 34.4406 42.1713 33.769 37.1242 33.9316H37.1196C36.871 33.7471 36.6255 33.5581 36.3831 33.3647C39.1868 33.2756 42.8696 33.4273 47.6679 34.0473C50.5092 34.4148 52.7533 34.7761 54.5549 35.1154Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M47.586 46.9755C47.586 46.9755 27.1601 50.2401 22.6872 51.4825C21.2394 51.8844 17.549 52.4466 13.2177 53.0237C12.6164 52.5431 12.0317 52.0479 11.4636 51.5381C15.8079 51.004 20.0809 50.405 23.1527 49.8076C32.4453 47.9975 47.586 46.9755 47.586 46.9755Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M19.467 45.7862C19.3904 45.8347 14.9933 48.6793 9.23872 49.3916C8.93807 49.0716 8.64024 48.7466 8.34521 48.4166C13.2078 48.2555 16.0223 47.1491 19.467 45.7862Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M57.3778 23.3889C56.5624 23.3474 55.7218 23.2943 54.8749 23.2325C49.4603 22.8118 35.3878 19.0641 34.2324 19.5817C33.077 20.0993 25.906 19.7615 25.906 19.7615C25.906 19.7615 31.8497 17.1319 49.9358 21.6436C49.9358 21.6436 53.0787 22.0784 57.0563 22.3028C57.1706 22.6593 57.2778 23.0214 57.3778 23.3889Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M0.670434 33.8846C0.60561 33.5369 0.547181 33.1851 0.495117 32.829C5.17474 33.0269 10.8076 33.0949 12.7094 32.4193C16.1617 31.1933 21.8903 28.2517 23.0793 28.3432C24.1443 28.4245 15.7054 34.5805 0.670434 33.8846Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M29.2513 5.77371C27.9657 5.74109 26.6836 5.62276 25.4131 5.41949C29.7374 5.60325 32.284 5.82531 29.2513 5.77371Z"
                          fill="#DEA263"
                        />
                        <path
                          d="M58.2322 28.8248C55.4529 29.4144 50.5184 30.2401 47.5859 29.4402C47.5859 29.4402 54.2256 29.1211 58.2016 28.2798C58.2146 28.4612 58.2238 28.6426 58.2322 28.8248Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M25.4061 13.6047C25.1258 13.5883 15.6824 13.0308 3.82178 16.992C4.17908 16.3664 4.55832 15.7568 4.95951 15.1631C10.1835 13.9589 18.2963 12.6007 25.4061 13.6047Z"
                          fill="#CA874C"
                        />
                        <g filter="url(#filter0_d_60_1081)">
                          <path
                            d="M17.8094 29.0567C25.4123 25.5807 30.4821 23.2892 33.0188 22.182C40.2615 19.0208 41.7665 18.4717 42.7474 18.4536C42.9632 18.4496 43.4455 18.5057 43.758 18.7717C44.0219 18.9964 44.0945 19.2999 44.1292 19.5129C44.1639 19.7259 44.2206 20.2127 44.1728 20.5902C43.228 28.0565 42.9847 29.4637 41.218 40.2659C40.8817 42.3223 40.1326 43.0044 39.4357 43.0717C38.2364 43.1875 37.2656 42.4613 36.185 41.6529C35.9011 41.4405 35.6095 41.2224 35.3042 41.0124C34.1062 40.1883 33.1803 39.5266 32.2519 38.8633L32.2514 38.8629C31.4012 38.2553 30.549 37.6463 29.484 36.9099C27.2678 35.3774 28.1955 34.4389 29.4326 33.1875C31.0602 31.541 35.0453 26.5523 36.5706 24.647C36.3404 24.7018 32.6735 27.2451 25.5699 32.2769C24.529 33.0269 23.5862 33.3923 22.7416 33.3732C21.8104 33.3521 20.0191 32.8207 18.6875 32.3665C18.4919 32.2998 18.3012 32.2368 18.1171 32.1761C16.7636 31.7295 15.7697 31.4015 15.8692 30.5687C15.928 30.0759 16.5748 29.5719 17.8094 29.0567Z"
                            fill="#451000"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M36.5706 24.647C36.3404 24.7018 32.6735 27.2451 25.5699 32.2769C24.529 33.0269 23.5862 33.3923 22.7416 33.3732C21.8104 33.3521 20.0191 32.8207 18.6875 32.3665C18.4919 32.2998 18.3012 32.2368 18.1171 32.1761C16.7636 31.7295 15.7697 31.4015 15.8692 30.5687C15.928 30.0759 16.5748 29.5719 17.8094 29.0567"
                            fill="#451000"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_60_1081"
                            x="15.8623"
                            y="18.4534"
                            width="29.1078"
                            height="25.8769"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.778973" dy="1.24636" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_60_1081"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_60_1081"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </a>
                    <a className="transition-all hover:opacity-75">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="61"
                        height="62"
                        viewBox="0 0 61 62"
                        fill="none"
                      >
                        <path
                          d="M60.1504 34.0499C59.578 39.9482 57.1298 45.4785 53.6092 50.0186C52.974 50.3418 52.2975 50.5732 51.5994 50.706C46.7239 51.6368 48.6014 51.5282 48.6014 51.5282L50.1284 53.8601C44.9266 58.7458 38.3948 61.7572 31.2774 61.5265C24.0354 61.2886 17.4016 57.8308 12.1578 52.2259C6.95214 46.6266 3.13646 38.8817 2.4037 30.2052C2.25892 28.5246 2.24538 26.835 2.36321 25.1522L6.40548 23.656L2.66767 22.5024C3.55073 16.9982 5.93825 12.1695 9.56314 8.68954C14.7696 3.60875 22.5676 1.35221 31.2774 2.72865C39.2319 3.98537 44.9048 5.37292 50.0591 10.2468C49.1566 11.9967 42.8779 14.3285 41.6748 14.3285H53.3032C58.3391 19.8747 60.8684 26.5984 60.1504 34.0499Z"
                          fill="#451000"
                        />
                        <path
                          d="M58.6233 31.7181C58.0517 37.6163 55.6035 43.1467 52.0821 47.6867C51.4469 48.0101 50.7704 48.2415 50.0723 48.3741C45.1968 49.305 42.1466 49.9258 40.1617 50.8566C40.3743 50.7726 42.9993 49.7736 45.4919 50.8614C46.2372 51.1873 47.3453 51.4164 48.6013 51.5282C43.4003 56.414 36.8677 59.4253 29.7503 59.1946C22.5083 58.9567 15.8745 55.499 10.6307 49.8941C5.42503 44.294 1.60935 36.5499 0.87659 27.8734C0.731813 26.1928 0.718291 24.5031 0.836114 22.8203L4.87838 21.3242L1.14058 20.1705C2.02442 14.6663 4.41116 9.83686 8.03605 6.35769C13.2425 1.2769 21.0444 -0.97964 29.7503 0.396804C37.7048 1.65352 44.9048 5.37294 50.0591 10.2468C46.8609 10.8811 41.3508 11.9967 40.1477 11.9967H51.7761C56.812 17.5397 59.3413 24.2665 58.6233 31.7181Z"
                          fill="#DEA263"
                        />
                        <path
                          d="M28.8931 12.968C28.4259 12.8633 20.7619 11.165 17.6393 12.1339C14.4177 13.1353 11.3699 12.8118 10.6737 12.9728C10.2384 13.0742 5.88543 13.2503 2.67871 13.3692C3.22225 12.7634 3.78629 12.1751 4.37084 11.6042C4.43937 11.5368 4.50867 11.4702 4.57875 11.4028C5.98977 11.2593 7.44986 11.2403 8.64207 11.5099C12.1688 12.3083 17.13 11.2229 20.0042 11.2998C22.7756 11.3743 28.4913 12.8618 28.8931 12.968Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M54.9089 16.1007C53.6163 16.199 51.8447 16.3274 49.6853 16.4749C45.358 16.7683 39.5481 16.3869 38.4719 16.31C39.464 16.371 44.394 16.6557 47.438 16.2973C49.3193 16.0769 52.2854 15.7732 54.5546 15.548C54.6691 15.7312 54.7882 15.9159 54.9089 16.1007Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M34.0114 45.1074C29.9512 45.3231 24.399 45.4246 20.595 44.7197C14.4712 43.5843 12.1008 44.0061 6.48323 44.4937C6.22107 44.0782 5.96592 43.6554 5.71777 43.2251C10.1806 43.2148 19.1559 43.5193 22.4094 44.2812C24.6957 44.8156 29.6919 44.8902 33.6547 44.8513C33.7739 44.9354 33.893 45.0218 34.0114 45.1074Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M14.0766 46.8439C14.0766 46.8439 11.6626 46.87 8.27907 47.0905C8.216 47.0112 8.15527 46.9263 8.09375 46.8439H14.0766Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M58.251 34.2942C58.2292 34.4107 58.2067 34.5273 58.1818 34.6438C54.9953 34.5875 30.9752 34.1285 24.7058 33.0533C22.4032 32.6569 12.9185 33.2262 12.3875 33.2579C12.9006 33.2079 21.6393 32.358 24.3437 32.4539C27.13 32.5506 30.8561 33.6147 43.7087 33.9699C49.6393 34.1324 54.5818 34.2585 58.251 34.2942Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M33.5722 48.2435C33.4289 48.6526 32.8962 49.1489 32.0459 49.6596C33.087 48.8667 33.5722 48.2435 33.5722 48.2435Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M30.8327 5.24459C24.7261 2.84692 20.2983 5.93361 15.7857 6.71063C13.1046 7.17288 9.43767 7.41551 6.90063 7.53603C7.1602 7.2416 7.42965 6.95537 7.70894 6.67733C9.7624 6.73283 12.8484 6.43233 17.3976 5.24539C28.277 2.40766 30.7813 5.18592 30.8327 5.24459Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M56.5784 19.2095L27.1689 17.929C27.1689 17.929 32.6658 16.9712 38.3823 17.282C42.7127 17.5199 51.8267 18.2882 56.2475 18.5134C56.3622 18.7444 56.4725 18.9764 56.5784 19.2095Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M17.4787 25.6786C16.267 26.5302 13.4987 27.7481 7.70199 27.5427C4.69151 27.4365 2.49711 27.7869 0.913995 28.2936C0.900757 28.1532 0.888299 28.0137 0.875839 27.8733C0.86338 27.733 0.854803 27.6141 0.845459 27.4848C2.93941 26.8505 5.64152 26.3082 8.23696 26.6127C12.5058 27.1066 15.8956 26.6111 17.4787 25.6786Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M18.3135 24.9C18.1578 25.1815 17.8767 25.4439 17.4788 25.6786C18.1025 25.241 18.3135 24.9 18.3135 24.9Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M58.6466 27.0409C52.6505 25.9769 42.595 30.5232 37.1955 29.6035C31.0367 28.5545 34.0417 23.9669 39.2326 22.2574C42.574 21.1561 52.0439 22.6356 58.1295 23.7861C58.1459 23.8575 58.1622 23.9296 58.1778 24.001C46.9644 22.301 42.5919 22.9972 38.3861 23.8115C33.5706 24.7447 31.5981 32.2065 44.4235 27.8552C51.892 25.318 56.2536 25.7382 58.596 26.5295C58.6123 26.6976 58.6302 26.8688 58.6466 27.0409Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M54.208 44.6047C54.0341 44.8912 53.855 45.1746 53.6707 45.4547C50.6797 45.917 42.9759 47.2522 36.0236 49.657C27.2491 52.6961 25.5204 50.587 25.49 50.5482C25.5507 50.5775 28.0924 51.7613 37.1932 47.8365C41.6124 45.9344 48.3677 45.0297 54.208 44.6047Z"
                          fill="#CA874C"
                        />
                        <path
                          d="M45.7115 53.9418C45.2536 54.2795 44.7903 54.6046 44.3168 54.9146L30.8335 54.2803C33.851 54.3913 41.4831 54.1138 45.7115 53.9418Z"
                          fill="#CA874C"
                        />
                        <g filter="url(#filter0_d_60_1100)">
                          <path
                            d="M40.4884 21.2327C38.652 20.3596 36.6828 19.7163 34.6238 19.3479C34.5863 19.3408 34.5488 19.3586 34.5295 19.3941C34.2763 19.8609 33.9957 20.4698 33.7993 20.9484C31.5847 20.6048 29.3815 20.6048 27.2124 20.9484C27.0159 20.4591 26.7251 19.8609 26.4707 19.3941C26.4514 19.3598 26.414 19.342 26.3765 19.3479C24.3186 19.7152 22.3494 20.3584 20.5119 21.2327C20.4959 21.2398 20.4823 21.2517 20.4733 21.2671C16.738 27.0493 15.7148 32.6895 16.2167 38.2597C16.219 38.287 16.2338 38.313 16.2542 38.3296C18.7186 40.2049 21.1058 41.3434 23.4487 42.098C23.4862 42.1098 23.5259 42.0956 23.5498 42.0636C24.104 41.2794 24.598 40.4525 25.0216 39.5829C25.0466 39.532 25.0227 39.4716 24.9717 39.4515C24.188 39.1435 23.4419 38.7679 22.7241 38.3415C22.6674 38.3071 22.6628 38.223 22.7151 38.1827C22.8661 38.0654 23.0172 37.9434 23.1614 37.8202C23.1875 37.7977 23.2239 37.7929 23.2545 37.8071C27.9698 40.0379 33.0747 40.0379 37.7344 37.8071C37.7651 37.7918 37.8014 37.7965 37.8286 37.819C37.9729 37.9422 38.1239 38.0654 38.2761 38.1827C38.3284 38.223 38.3249 38.3071 38.2682 38.3415C37.5504 38.7762 36.8043 39.1435 36.0195 39.4503C35.9684 39.4704 35.9457 39.532 35.9707 39.5829C36.4034 40.4513 36.8974 41.2782 37.4414 42.0624C37.4641 42.0956 37.505 42.1098 37.5425 42.098C39.8967 41.3434 42.2839 40.2049 44.7483 38.3296C44.7699 38.313 44.7835 38.2881 44.7858 38.2609C45.3866 31.8211 43.7796 26.2272 40.5259 21.2682C40.5179 21.2517 40.5043 21.2398 40.4884 21.2327ZM25.7258 34.868C24.3061 34.868 23.1364 33.5176 23.1364 31.859C23.1364 30.2005 24.2835 28.85 25.7258 28.85C27.1794 28.85 28.3378 30.2124 28.3151 31.859C28.3151 33.5176 27.168 34.868 25.7258 34.868ZM35.2995 34.868C33.8799 34.868 32.7102 33.5176 32.7102 31.859C32.7102 30.2005 33.8572 28.85 35.2995 28.85C36.7532 28.85 37.9116 30.2124 37.8889 31.859C37.8889 33.5176 36.7532 34.868 35.2995 34.868Z"
                            fill="#451000"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_60_1100"
                            x="16.0977"
                            y="19.3464"
                            width="29.5912"
                            height="24.0021"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.778973" dy="1.24636" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_60_1100"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_60_1100"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="py-3 text-center uppercase">
        <span>© 2024 by solfarm</span>
      </footer>
    </div>
  );
}

export default App;
